// 入驻须知   ok！
<template>
  <div>
    <p style="text-align:center">
    <strong><span style=";font-family:宋体;font-size:24px">“研通小店”开店流程及费用标准</span></strong><strong></strong>
</p>
<p style="text-align:right">
    <span style=";font-family:宋体;font-size:16px">2022年1.0版本</span>
</p>
<p>
    <strong><span style=";font-family:宋体;font-size:19px"><span style="font-family:宋体">第一章</span> 入驻须知</span></strong><strong></strong>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px"><span style="font-family:宋体">第一条</span> 研通于2022年3月开启“研通小店”招商工作，各城市授权服务商，凭借授权书授予的权限与区域进行招商工作，入驻申请流程及相关的收费说明均以官方招商说明为准。</span>
</p>
<!-- <p>
    <span style=";font-family:宋体;font-size:17px">&nbsp;</span>
</p> -->
<p>
    <span style=";font-family:宋体;font-size:17px"><span style="font-family:宋体">第二条</span> 研通有权根据包括但不仅限于品牌需求、公司经营状况、服务水平等其他因素退回客户申请；同时研通有权在申请入驻及后续经营阶段要求客户提供其他资质；研通将结合本行业发展动态、国家相关规定及求学者需求，不定期更新招商标准。</span>
</p>
<!-- <p>
    <span style=";font-family:宋体;font-size:17px">&nbsp;</span>
</p> -->
<p>
    <span style=";font-family:宋体;font-size:17px"><span style="font-family:宋体">第三条</span> 商家必须如实提供资料和信息。</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px"><span style="font-family:宋体">（一）</span> 请务必确保您申请入驻及后续经营阶段提供的相关资质和信息的真实性（若您提供的相关资质为第三方提供，包括但不限于商标注册证、授权书等，请务必先行核实文件的真实有效性），一旦发现虚假资质或信息的，您的公司将被列入非诚信客户名单，研通将不再与您进行合作；</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px"><span style="font-family:宋体">（二）</span> 商家应如实提供其店铺运营的主体及相关信息，包括但不限于代理运营商、实际店铺经营主体等信息；</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px"><span style="font-family:宋体">（三）</span> 研通关于商家信息和资料变更有相关规定的从其规定，但商家如变更第二款所列信息，应（提前十五天）书面告知研通。</span>
</p>
<!-- <p>
    <span style=";font-family:宋体;font-size:17px">&nbsp;</span>
</p> -->
<p>
    <span style=";font-family:宋体;font-size:17px"><span style="font-family:宋体">第四条</span> 研通暂不接受非中国大陆企业/个人的入驻申请，亦不接受有过失信行为企业/个人的入驻申请。</span>
</p>
<!-- <p>
    <span style=";font-family:宋体;font-size:17px">&nbsp;</span>
</p> -->
<p>
    <span style=";font-family:宋体;font-size:17px"><span style="font-family:宋体">第五条</span> 研通暂不接受未取得国家商标总局颁发的商标注册证或商标受理通知书的企业开品牌旗舰店申请，亦不接受纯图形类商标的入驻申请。</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">&nbsp;</span>
</p>
<p>
    <strong><span style=";font-family:宋体;font-size:19px"><span style="font-family:宋体">第二章</span> 研通小店类型及相关要求</span></strong>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px"><span style="font-family:宋体">第六条</span> 旗舰店，商家以自有品牌（商标为R或TM状态），或由权利人独占性授权，入驻研通开设的店铺</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">(一) 旗舰店，可以有以下几种情形：</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">1、经营一个自有品牌课程/教材的旗舰店；</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">2、经营多个自有品牌课程/教材且各品牌归同一实际控制人；</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">3、暂无品牌（商标申请准备中）开设的旗舰店； 其中2、3两种类型旗舰店仅限研通主动邀请入驻。</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">(二) 开店主体必须是品牌（商标）权利人或持有权利人出具的开设研通旗舰店独占性授权文件的企业。</span>
</p>
<!-- <p>
    <span style=";font-family:宋体;font-size:17px">&nbsp;</span>
</p> -->
<p>
    <span style=";font-family:宋体;font-size:17px"><span style="font-family:宋体">第七条</span> 专卖店，商家持他人品牌（商标为R或TM状态）授权文件在研通开设的店铺。</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">(一) 专卖店有以下几种情形：</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">1、经营一个授权销售品牌课程/教材的专卖店；</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">2、经营多个授权销售品牌的课程/教材且各课程/教材归同一实际控制人的专卖店；其中第2点所述类型的专卖店仅限研通主动邀请入驻。</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">(二) 品牌（商标）权利人出具的授权文件不得有地域限制，且授权有效期不得早于2024年12月31日。</span>
</p>
<!-- <p>
    <span style=";font-family:宋体;font-size:17px">&nbsp;</span>
</p> -->
<p>
    <span style=";font-family:宋体;font-size:17px"><span style="font-family:宋体">第八条</span> 专营店，经营研通视频、直播、线下、教材类别中两个及以上他人或自有品牌（商标为R或TM状 态）的店铺。一个招商大类下专营店只能申请一家。</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">&nbsp;</span>
</p>
<p>
    <strong><span style=";font-family:宋体;font-size:19px"><span style="font-family:宋体">第三章</span> 研通申请入驻资质标准</span></strong>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px"><span style="font-family:宋体">第九条</span> 研通申请入驻资质标准：</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">(一) 企业入驻：录播、直播考研资讯、培训类，需持第35、38、41、45类中任意一类注册商标或商标授权；线下培训类，需持第41类注册商标或商标授权，同时营业执照中需有培训/教育/辅导</span><span style=";font-family:宋体;font-size:17px">或相关的经营范围或具有办学许可证</span><span style=";font-family:宋体;font-size:17px">；教材售卖暂无特殊要求；</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">(二) 个人入驻：录播、直播考研培训类，需硕士或以上，持相关科目的教师资格证书、讲师资质证书、教授资质证书中任意一项；录播、直播考研资讯类，教材售卖暂无特殊要求；个人入驻为暂时开放，如遇国家政策、法规限制，使得个人入驻无法继续，则个人入驻商家需补充手续转至“企业入驻”或下线。</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">&nbsp;</span>
</p>
<p>
    <strong><span style=";font-family:宋体;font-size:19px"><span style="font-family:宋体">第四章</span> 开店入驻限制</span></strong>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px"><span style="font-family:宋体">第十条</span> 品牌入驻限制：</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">(一) 与研通（包括但不限于考研通、研通在线等）平台品牌、频道、业务、类目等相同或近似；</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">(二) 以“包过”、“保过”等涉嫌违规风险的品牌；</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">(三) 包含行业名称或通用名称的品牌；</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">(四) 包含知名人士、地名的品牌，又未获得授权的；</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">(五) 与知名品牌相同或近似的品牌。</span>
</p>
<!-- <p>
    <span style=";font-family:宋体;font-size:17px">&nbsp;</span>
</p> -->
<p>
    <span style=";font-family:宋体;font-size:17px"><span style="font-family:宋体">第十一条</span> 商号（或字号）入驻限制：</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">企业名称中的商号出现以下任一情形的，限制入驻：</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">(一) 与研通（包括但不限于考研通、研通在线等）平台品牌、频道、业务、类目等相同或近似；</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">(二) 与知名品牌相同的。</span>
</p>
<!-- <p>
    <span style=";font-family:宋体;font-size:17px">&nbsp;</span>
</p> -->
<p>
    <span style=";font-family:宋体;font-size:17px"><span style="font-family:宋体">第十二条</span> 同一主体开多家研通限制：</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">(一) 产品重合度：要求店铺间经营的培训及商品不得重复；</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">(二) 同一课程下专营店只能申请一家。</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px"><span style="font-family:宋体">第十三条</span> 同一主体重新入驻研通限制：</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">(一)严重违规、资质造假被研通清退的，永久限制入驻；</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">(二)商家一自然年内主动退出2次，6个月内限制入驻。</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">&nbsp;</span>
</p>
<p>
    <strong><span style=";font-family:宋体;font-size:19px"><span style="font-family:宋体">第五章</span> 研通保证金/年费/费率标准</span></strong><strong></strong>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px"><span style="font-family:宋体">第十四条</span> 保证金：商家在研通经营必须交纳保证金，保证金主要用于保证商家按照研通的规则进行经营，并且在商家有违规行为时根据《研通服务协议》及相关规则规定用于向研通及消费者支付违约金。续约商家须在当年续签要求的时间内一次性缴纳次年度保证金；新签商家在申请入驻获得批准时一次性缴纳纳当年度的保证金。保证金根据店铺性质不同，金额如下：</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">(一)品牌旗舰店、专卖店: 5万元；</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">(二)专营店：2万元；</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">(三)特殊类目说明：</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">(四)保证金不足额时，商家需要在15日内补足余额，逾期未补足的研通将对商家店铺进行监管，直至补足。</span>
</p>
<!-- <p>
    <span style=";font-family:宋体;font-size:17px">&nbsp;</span>
</p> -->
<p>
    <span style=";font-family:宋体;font-size:17px"><span style="font-family:宋体">第十五条</span> 技术服务费年费：</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">(一)研通基础功能: 免技术服务费；</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">(二)研通直播功能、电话坐席外呼功能对应的软件产品费用标准详见《考研通APP系统功能模块》。</span>
</p>
<!-- <p>
    <span style=";font-family:宋体;font-size:17px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">&nbsp;</span>
</p> -->
<!-- <p>
    <span style=";font-family:宋体;font-size:17px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:17px">&nbsp;</span>
</p> -->
<p>
    <span style=";font-family:宋体;font-size:17px">&nbsp;</span>
</p>
<p style="text-align:right;margin:5px 0;">
    <span style=";font-family:宋体;font-size:17px"><span style="font-family:宋体">考研通</span>APP运营部</span>
</p>
<p style="text-align:right;margin:5px 0;">
    <span style=";font-family:宋体;font-size:17px">江苏子信科技创新研究院有限公司</span>
</p>
<p style="text-align:right;margin:5px 0;">
    <span style=";font-family:宋体;font-size:17px">2022年3月5日</span>
</p>
<p>
    <br/>
</p>
  </div>
</template>
<script>
export default {

}
</script>

<style scoped>
  div {
    padding:20px
  }
  p {
    margin:10px 0
  }
</style>